<template>
  <my-header></my-header>
  <div class="container">
    <div class="top">
      <div class="top_left">
        <div class="top_left_title"> {{topInfo.title}} </div>
        <div class="top_left_text"> {{topInfo.text}} </div>
      </div>
      <div class="top_right">
        <img class="top_right_img" :src="topInfo.img_1">
        <img class="top_right_img" :src="topInfo.img_2">
      </div>
    </div>
    <div class="flow">
      <div class="flow_title">
        <div class="flow_title_content">建设内容</div>
        <div class="flow_title_text">{{flowInfo.title_1}}{{flowInfo.title_2}}</div>
      </div>
      <div class="service">
        <div class="service_box" v-for="item in flowInfo.list" :key="item">
          <div class="service_box_title"> {{item.title}} </div>
          <div class="item_box">
            <div class="text_box">
              <div class="text" v-for="item_2 in item.list" :key="item_2"> {{item_2.text}} </div>
            </div>
            <div class="text_box margin_left" v-if="item.list_2">
              <div class="text" v-for="text_2 in item.list_2" :key="text_2"> {{text_2.text}} </div>
            </div>
            <div class="text_box margin_left" v-if="item.list_3">
              <div class="text" v-for="text_3 in item.list_3" :key="text_3"> {{text_3.text}} </div>
            </div>
          </div>


        </div>
      </div>
    </div>
    <!--运营服务-->
    <div class="operation">
      <div class="title"> {{operationInfo.title}} </div>
      <div class="operation_box">

        <div class="operation_item" v-for="(item, index) in operationInfo.list" :key="item" :class="index == 0 ? 'first' : 'last'">
          <div class="operation_item_title"> {{item.title}} </div>
          <div class="operation_item_text"> {{item.text}} </div>
          <img class="operation_item_img_1" v-if="item.img" :src="item.img">
          <div class="img_2" v-if="item.imgList">
            <img class="operation_item_img_2" v-for="item_2 in item.imgList" :key="item_2" :src="item_2.imgSrc">
          </div>
        </div>

      </div>
    </div>
    <!--项目成果-->
    <div class="achievement">
      <div class="title"> {{achievementInfo.title}} </div>
      <div class="achievement_box">
        <div class="achievement_box_item" v-for="(item, index) in achievementInfo.list" :key="item" :class="index > 1 ? 'textClass': ''">
          <img class="item_icon" :src="item.icon">
          <div class="item_title"> {{item.title}} </div>
          <div class="item_num"> {{item.num}} </div>
          <div class="item_value"></div>
        </div>
      </div>
      <div class="achievement_case">
        <div class="headLine"> {{achievementInfo.caseHeadLine}} </div>
        <div class="title"> {{achievementInfo.caseTitle}} </div>
        <img class="QRcode" :src="achievementInfo.QRcode">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "case_detail",
    data(){
      return{
        topInfo:{
          title: '海南省人民医院',
          text: '海南省人民医院始建于1881年，是一所集医疗、急救、教学、科研、康复、预防和保健于一体的三级甲等综合性医院。\n' +
            '为了响应国家号召，海南省人民医院携手海南玉螺打造互联网+医疗服务平台，提供覆盖诊前、诊中、诊后全流程的互联网医疗服务，一站式解决患者的就医与健康管理需求、医护提高工作管理效率需求，以及增加医院核心竞争力和效益等需求。',
          img_1: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_top1.png',
          img_2: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_top2.png'
        },
        flowInfo:{
          title_1: '搭建「线上+线下」全流程',
          title_2: '互联网+医疗服务平台',
          list: [
            {
              id: 1,
              title: '患者服务',
              list: [
                {text: '在线建档'},
                {text: '健康卡管理'},
                {text: '智能导诊'},
                {text: '预约挂号'},
                {text: '预问诊'},
                {text: '在线问诊'},
                {text: '排队叫号'},
              ],
              list_2:[
                {text: '门诊缴费'},
                {text: '检查调整'},
                {text: '检查报告'},
                {text: '体检报告'},
                {text: '历次就诊'},
                {text: '药品配送'},
                {text: '用药咨询'},
              ],
              list_3:[
                {text: '门诊预缴'},
                {text: '入院通知'},
                {text: '住院陪护'},
                {text: '新冠自查'},
                {text: '核酸检测'},
                {text: '......'},

              ]
            },
            {
              id: 2,
              title: '医生服务',
              list: [
                {text: '病人管理'},
                {text: '术前访视'},
                {text: '耗材记账'},
                {text: '病历检索'},
                {text: '在线问诊'},
                {text: '患者360'},
                {text: '......'},
              ]
            },
            {
              id: 3,
              title: '便民服务',
              list: [
                {text: '医院简介'},
                {text: '门诊介绍'},
                {text: '科室介绍'},
                {text: '名医专家'},
                {text: '就医指南'},
                {text: '使用帮助'},
                {text: '......'},
              ]
            }
          ]
        },
        operationInfo:{
          title: '提供「院内+院外」增值运营服务',
          list: [
            {
              title:  '健康管理',
              text: '为慢病患者全健康周期服务管理提供一站式解决方案。',
              img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_jkgl.png'
            },
            {
              title:  '互联网+护理',
              text: '为失能、慢病、出院患者提供安全、专业、便捷的延续性护理服务，将护理服务延伸至社区和家庭。',
              imgList: [
                {imgSrc: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_hlwjhl_1.png'},
                {imgSrc: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_hlwjhl_2.png'}
              ]

            },
          ]
        },
        achievementInfo:{
          title: '项目成果',
          caseHeadLine: '查看案例',
          caseTitle: '使用微信的「扫一扫」功能，即刻体验',
          QRcode: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_QRcode.png',
          list: [
            // {
            //   icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_xmcg_1.png',
            //   title: '小程序累计浏览量',
            //   num: '1,000,000+人次',
            //   value: '（百万）'
            // },
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_xmcg_2.png',
              title: '活跃用户达到',
              num: '102万+',
              value: '（十万）'
            },
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_xmcg_3.png',
              title: '医院门诊量年增长',
              num: '10%↑',
            },
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_detail_rmyy_xmcg_4.png',
              title: '缴费率达到',
              num: '80%',
            },
          ]
        }
      }
    },
    mounted(){
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
</script>

<style lang="stylus" scoped>
  .container{
    display flex
    flex-flow column
    font-family Alibaba PuHuiTi 2.0
    font-weight normal
    background #F5F5F7
    box-sizing border-box
    padding 0 140px
    .top{
      display flex
      align-items center
      justify-content space-between
      margin-top 60px
      .top_left{
        display flex
        flex-flow column
        width 674px
        .top_left_title{
          font-size 56px
          line-height 67px
          color #0069D9
        }
        .top_left_text{
          font-size 20px
          line-height 34px
          color #6C757D
          margin-top 50px
        }
      }
      .top_right{
        display flex
        margin-right 126px
        .top_right_img{
          width 280px
          height 800px
        }
        .top_right_img:last-child{
          margin 80px 0 0 80px
        }
      }
    }
    .flow{
      display flex
      flex-flow column
      padding 120px 0 150px 0
      .flow_title{
        display flex
        flex-flow column
        font-size 56px
        line-height 67px
        color #23272B
        .flow_title_content{
          font-weight bold
          font-size 56px
          color #343A40
        }
        .flow_title_text{
          font-weight bold
          font-size 32px
          color #6C757D
          margin-top 27px
        }
      }
      .service{
        display flex
        margin-top 52px
        .service_box{
          display flex
          flex-flow column
          background rgba(0, 123, 255, 0.1)
          box-sizing border-box
          padding 40px
          border-radius 16px
          margin-right 30px
          .service_box_title{
            font-size 32px
            line-height 45px
            color #007BFF
          }
          .item_box{
            display flex
            .text_box{
              display flex
              flex-flow column
              background #007BFF
              border-radius 16px
              padding 40px 38px
              margin-top 30px
              .text{
                font-size 20px
                line-height 28px
                color #FFFFFF
                text-align center
              }
            }
            .margin_left{
              margin-left 30px
            }
          }

        }
      }
    }
    .operation{
      display flex
      flex-flow column
      .title{
        font-size 32px
        line-height 67px
        font-weight 500
        color #6C757D
      }
      .operation_box{
        display flex
        justify-content space-between
        margin-top 100px
        .operation_item{
          display flex
          flex-flow column
          background #FFFFFF
          border-radius 24px
          box-sizing border-box
          padding 50px 72px
          .operation_item_title{
            font-size 40px
            line-height 56px
            color #007BFF
          }
          .operation_item_text{
            font-size 20px
            line-height 34px
            color #6C757D
            margin-top 20px

          }
          .operation_item_img_1{
            width 392px
            height 800px
            margin-top 40px
          }
          .img_2{
            display flex
            align-items center
            justify-content center
            margin-top 20px
            .operation_item_img_2{
              width 280px
              height 800px
            }
            .operation_item_img_2:first-child{
              margin 60px 58px 0 0
            }

          }

        }
        .first>.operation_item_text{
          width 392px
        }
        .last{
          flex 1
          margin-left 16px
        }
        .last>.operation_item_text{
          width 638px
        }
      }

    }
    .achievement{
      display flex
      flex-flow column
      margin-top 150px
      .title{
        font-size 56px
        line-height 67px
        color #23272B
      }
      .achievement_box{
        display flex
        justify-content space-between
        margin-top 50px
        .achievement_box_item{
          background #fff
          display flex
          flex-flow column
          justify-content center
          align-items center
          border-radius 24px
          width 536px
          height 489px
          /*margin-left 16px*/
          .item_icon{
            width 140px
            height 140px
          }
          .item_title{
            font-size 20px
            line-height 28px
            color #6C757D
            margin-top 37px
          }
          .item_num{
            font-size 44px
            line-height 62px
            color #343A40
            font-weight bold
          }
          .item_value{
            font-size 20px
            line-height 28px
            color #6C757D
          }
        }
        .textClass>.item_num{
          /*font-size 72px*/
          /*line-height 100px*/
        }
      }

      .achievement_case{
        background #ffffff
        display flex
        flex-flow column
        justify-content center
        align-items center
        border-radius 24px
        box-sizing border-box
        padding 80px 0 112px 0
        margin-top 16px
        .headLine{
          font-size 72px
          line-height 100px
          color #343A40
        }
        .title{
          font-size 20px
          line-height 28px
          color #6C757D
        }
        .QRcode{
          width 400px
          height 400px
          margin-top 80px
        }
      }
    }
  }
</style>
